<template lang="pug">
div
  textarea.editor(
    ref="config"
    :height="height"
  )
    | {{ this.json }}
</template>

<script>
import CodeMirror from 'codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/cobalt.css';

export default {
  name: 'EditJson',

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    height: {
      type: Number,
      default: 800,
    },
  },

  data() {
    return {
      countChanges: 0,
      editor: null,
      json: null,
    };
  },

  watch: {
    // json: {
    //   handler: function (content) {
    //     // if (content && this.editor) {
    //     //   this.editor.setValue(content);
    //     // }
    //     this.$emit('input', content);
    //   },
    //   deep: true,
    // },

    countChanges(value) {
      try {
        this.$emit('input', JSON.parse(this.editor.getValue()));
      } catch (error) {
        return error;
      }
    },
  },

  created() {
    this.json = this.value;
  },

  mounted() {
    this.editor = CodeMirror.fromTextArea(this.$refs.config, {
      value: this.json,
      lineNumbers: true,
      mode: 'json',
      theme: 'cobalt',
      viewportMargin: Infinity,
    });

    this.editor.setSize('100%', this.height);
    this.editor.on(
      'change',
      function (c, b) {
        this.countChanges += 1;
      }.bind(this),
    );
  },
};
</script>
